<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Data Lowongan Kerja"
      header-bg-variant="warning"
      header-text-variant="light"
    >
      <b-tabs content-class="mt-3" align="center">
        <b-tab title="Data Loker">
          <edit-tab-loker
            :data="data"
            :posisi="posisi"
            @dataFromChild="fromData($event)"
            :divisi="divisi"
          />
        </b-tab>
        <b-tab title="Syarat Dok. Administrasi Loker">
          <edit-tab-dok-loker
            @dokumenFromChild="fromDokumen($event)"
            :dokumen="dokumen"
            :listDokumen="listDokumen"
          />
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Batal
        </b-button>
        <b-button
          v-if="dataLoker && dataDokumen"
          :disabled="busy"
          variant="primary"
          v-b-modal.modal-konfirmasi-edit
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-konfirmasi-edit"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <p>Apakah data Anda sudah sesuai?</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="batal"> Batal </b-button>
        <b-button variant="primary" :disabled="busy" @click="create">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import editTabLoker from "../../../component/hcms/lowonganKerja/editTabLoker.vue";
import editTabDokLoker from "../../../component/hcms/lowonganKerja/editTabDokLoker.vue";
export default {
  components: {
    editTabLoker,
    editTabDokLoker,
  },
  name: "modalEdit",
  props: ["data", "divisi", "edit", "dokumen"],
  data() {
    return {
      variant: "",
      msg: "",
      button: "Simpan",
      busy: false,
      dataLoker: "",
      dataDokumen: "",
      listDokumen: [],
      posisi: [],
    };
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        // FOREACH diganti FOR
        // this.posisi.forEach((ele) => {
        //   if (ele.masterPosisiId == this.data.masterPosisiId) {
        //     this.data.masterPosisiIdEdit = ele;
        //   }
        // });
        for (let i = 0; i < this.posisi.length; i++) {
          if (this.posisi[i].masterPosisiId == this.data.masterPosisiId) {
            this.data.masterPosisiIdEdit = this.posisi[i];
          }
        }
        this.getPosisi();
        this.getDokumen();
        this.dataLoker = this.data;
        console.log(this.dataLoker, this.dataDokumen);
      }
    },
  },
  methods: {
    getDokumen() {
      let vm = this;
      let id = vm.data.lokerId;
      axios
        .get(ipBackend + "loker/listByKebutuhanLokerId/" + id)
        .then((res) => {
          vm.listDokumen = res.data.data;
          this.dataDokumen = this.listDokumen;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fromData(data) {
      this.dataLoker = data;
    },
    fromDokumen(data) {
      this.dataDokumen = data.data;
    },
    getPosisi() {
      let vm = this;
      axios
        .get(ipBackend + "masterPosisi/list")
        .then((res) => {
          vm.posisi = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    create() {
      let vm = this;
      let tglAkhir = moment(vm.dataLoker.tanggalAkhirLoker);
      let bulkKebutuhan = [];
      for (let i = 0; i < vm.dataDokumen.length; i++) {
        let penampung = {};
        penampung.masterKebutuhanId = vm.dataDokumen[i].masterKebutuhanId;
        bulkKebutuhan.push(penampung);
      }
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      axios
        .post(ipBackend + "loker/update", {
          id: vm.dataLoker.lokerId,
          namaLoker: vm.dataLoker.namaLoker,
          keteranganLoker: vm.dataLoker.keteranganLoker,
          tanggalAkhirLoker: tglAkhir,
          masterPosisiId: vm.dataLoker.masterPosisiId.masterPosisiId,
          bulkKebutuhan: JSON.stringify(bulkKebutuhan),
        })
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH DATA LOWONGAN KERJA",
              showing: true,
            });
            vm.dataLoker = "";
            vm.dataDokumen = "";
            vm.button = "Simpan";
            vm.$bvModal.hide("modal-edit");
            vm.$bvModal.hide("modal-konfirmasi-edit");
          } else {
            vm.busy = false;
            vm.button = "Simpan";
            vm.$bvModal.hide("modal-edit");
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.busy = false;
          vm.button = "Simpan";
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    batal() {
      this.$bvModal.hide("modal-konfirmasi-edit");
    },
  },
};
</script>

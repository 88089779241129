<template>
  <div>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-form>
          <b-form-group label="Syarat Dok. Administrasi" label-cols-md="3">
            <multiselect
              v-model="dokumenId"
              :options="dokumen"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              label="namaKebutuhan"
              track-by="id"
              placeholder="-- Pilih Dokumen Administrasi --"
            ></multiselect>
          </b-form-group>

          <b-form-group label-cols-md="3">
            <b-button variant="primary" @click="simpan()">Tambahkan</b-button>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <hr />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="12" lg="12">
        <b-row>
          <b-col md="3">
            <b-form-group
              label="Per Halaman"
              label-for="per-page-select"
              label-cols-md="7"
              label-align-md="left"
              label-size="md"
              style="background-color: "
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="md"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="6" offset-md="3">
            <b-form-group
              label="Cari"
              label-for="filter-input"
              label-cols-md="3"
              label-align-md="right"
              label-size="md"
            >
              <b-input-group size="md">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Ketik disini untuk mencari ..."
                ></b-form-input>

                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                    variant="danger"
                    >Hapus</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-table
              :items="listDokumen"
              :fields="fields"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              stacked="md"
              responsive
              show-empty
              small
              @filtered="onFiltered"
              bordered
              striped
              hover
            >
              <template #cell(no)="item">
                {{ item.index + 1 }}
              </template>
              <template #cell(actions)="item">
                <b-button
                  variant="danger"
                  class="mr-1"
                  v-c-tooltip.hover.click="'Hapus Data'"
                  @click="deleteItem(row.index)"
                  ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                >
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="5" offset-md="7">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <modal-delete-dok />
  </div>
</template>
<script>
import modalDeleteDok from "../../../component/hcms/lowonganKerja/modalDeleteDok.vue";

// import axios from "axios";
// import { ipBackend } from "@/ipBackend";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  components: {
    Multiselect,
    modalDeleteDok,
  },
  name: "inputTabDokLoker",
  props: ["dokumen", "listDokumen"],
  data() {
    return {
      dokumenId: "",
      kebutuhan: {},
      fields: [
        {
          key: "no",
          label: "No",
          sortable: true,
          sortDirection: "desc",
          class: "table-number text-center",
        },
        {
          key: "namaKebutuhan",
          label: "Syarat Dokumen Administrasi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-1 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    simpan() {
      let vm = this;
      let list = {};
      list.namaKebutuhan = vm.dokumenId.namaKebutuhan;
      list.masterKebutuhanId = vm.dokumenId.id;
      vm.listDokumen.push(list);
      vm.kebutuhan.data = vm.listDokumen;
      vm.$emit("dokumenFromChild", vm.kebutuhan);
    },
    deleteItem(index) {
      this.items.splice(index, 1);
      this.kebutuhan.data = this.items;
      this.$emit("dokumenFromChild", this.kebutuhan);
    },
  },
};
</script>

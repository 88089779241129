<template>
  <div>
    <b-form>
      <b-form-group label="Judul" label-cols-md="3">
        <b-form-input
          type="text"
          :state="checkIfValid('namaLoker')"
          v-model="$v.data.namaLoker.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Umur Minimal" label-cols-md="3">
        <b-form-input
          type="number"
          :state="checkIfValid('umurMinimal')"
          v-model="$v.data.umurMinimal.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Umur Maksimal" label-cols-md="3">
        <b-form-input
          type="number"
          :state="checkIfValid('umurMaksimal')"
          v-model="$v.data.umurMaksimal.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Divisi
          <!-- <span class="text-danger">*</span> -->
        </template>
        <multiselect
          v-model="data.masterDivisiId"
          :options="divisi"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          track-by="id"
          @input="getPosisi(data.masterDivisiId)"
          label="namaDivisi"
          placeholder="-- Pilih Divisi --"
        ></multiselect>
      </b-form-group>
      <b-form-group label="Posisi" label-cols-md="3">
        <multiselect
          :state="checkIfValid('masterPosisiId')"
          v-model="$v.data.masterPosisiId.$model"
          :options="posisi"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="namaPosisi"
          track-by="masterPosisiId"
          @input="itikiwir(data.masterPosisiId)"
          placeholder="-- Pilih Posisi --"
        ></multiselect>
      </b-form-group>
      <b-form-group label="Keterangan" label-cols-md="3">
        <quill-editor v-model="data.keteranganLoker"></quill-editor>
      </b-form-group>
      <b-form-group label="Tanggal Akhir Lowongan" label-cols-md="3">
        <date-picker
          style="width: 100%"
          :state="checkIfValid('tanggalAkhirLoker')"
          v-model="$v.data.tanggalAkhirLoker.$model"
        ></date-picker>
      </b-form-group>

      <b-form-group label-cols-md="3">
        <template v-slot:label> Foto Poster </template>
        <b-form-file
          id="file1"
          ref="file1"
          type="file"
          @input="handleFile()"
          @change="ngirim()"
        ></b-form-file>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "modalInput",
  props: ["divisi"],
  components: {
    DatePicker,
    Multiselect,
    quillEditor,
  },
  data() {
    return {
      data: {
        namaLoker: "",
        masterDivisiId: "",
        masterPosisiId: "",
        keteranganLoker: "",
        tanggalAkhirLoker: "",
        umurMinimal: "",
        umurMaksimal: "",
        file1: "",
      },
      posisi: [],
      src1: "",
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      namaLoker: {
        required,
      },
      umurMinimal: {
        required,
      },
      umurMaksimal: {
        required,
      },
      masterPosisiId: {
        required,
      },
      tanggalAkhirLoker: {
        required,
      },
    },
  },
  watch: {
    isValid: function (newVal, oldVal) {
      if (newVal != oldVal) {
        console.log(newVal);
        // this.data.masterPosisiId = this.data.masterPosisiId.masterPosisiId;
        this.$emit("dataFromChild", this.data);
      }
    },
  },
  methods: {
    handleFile() {
      this.data.file1 = this.$refs.file1.files[0];
      this.src1 = URL.createObjectURL(this.data.file1);
    },
    ngirim() {
      let vm = this;
      this.$emit("dataFromChild", vm.data);
    },
    async getPosisi(x) {
      let vm = this;
      vm.posisi = [];
      vm.data.masterPosisiId = "";
      let posisi = await axios.get(
        ipBackend + "masterPosisi/listPosisiByMasterDivisiId/" + x.id
      );
      console.log(posisi.data.data);

      vm.posisi = posisi.data.data;
    },
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {},
  },
};
</script>

<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Lowongan Kerja"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-tabs content-class="mt-3" align="center">
        <b-tab title="Data Loker">
          <input-tab-loker @dataFromChild="fromData($event)" :divisi="divisi" />
        </b-tab>
        <b-tab title="Syarat Dok. Administrasi Loker">
          <input-tab-dok-loker
            @dokumenFromChild="fromDokumen($event)"
            :dokumen="dokumen"
          />
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button
          v-if="dataLoker != '' && dataDokumen != ''"
          :disabled="busy"
          variant="primary"
          v-b-modal.modal-konfirmasi-input
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="modal-konfirmasi-input"
      size="md"
      centered
      title="Perhatian .... !!!"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <p>Apakah data Anda sudah sesuai?</p>

      <template #modal-footer>
        <b-button variant="secondary" @click="batal"> Batal </b-button>
        <b-button variant="primary" :disabled="busy" @click="create">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import inputTabLoker from "../../../component/hcms/lowonganKerja/inputTabLoker.vue";
import inputTabDokLoker from "../../../component/hcms/lowonganKerja/inputTabDokLoker.vue";
import moment from "moment";
export default {
  components: {
    inputTabLoker,
    inputTabDokLoker,
  },
  name: "modalInput",
  props: ["divisi", "dokumen"],
  data() {
    return {
      variant: "",
      msg: "",
      button: "Simpan",
      busy: false,
      dataLoker: "",
      dataDokumen: "",
    };
  },
  methods: {
    fromData(data) {
      console.log(data, "ini datanyaaa");
      this.dataLoker = data;
    },
    fromDokumen(data) {
      this.dataDokumen = data.data;
    },

    create() {
      let vm = this;
      let tglAkhir = moment(vm.dataLoker.tanggalAkhirLoker);
      let bulkKebutuhan = [];
      // FOREACH diganti FOR
      // vm.dataDokumen.forEach((element) => {
      //   let penampung = {};
      //   penampung.masterKebutuhanId = element.masterKebutuhanId;
      //   bulkKebutuhan.push(penampung);
      // });
      for (let i = 0; i < vm.dataDokumen.length; i++) {
        let penampung = {};
        penampung.masterKebutuhanId = vm.dataDokumen[i].masterKebutuhanId;
        bulkKebutuhan.push(penampung);
      }
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      let formData = new FormData();
      formData.append("namaLoker", vm.dataLoker.namaLoker);
      formData.append("keteranganLoker", vm.dataLoker.keteranganLoker);
      formData.append("tanggalAkhirLoker", tglAkhir);
      formData.append("umurMinimal", vm.dataLoker.umurMinimal);
      formData.append("umurMaksimal", vm.dataLoker.umurMaksimal);
      formData.append(
        "masterPosisiId",
        vm.dataLoker.masterPosisiId.masterPosisiId
      );
      formData.append("bulkKebutuhan", JSON.stringify(bulkKebutuhan));
      formData.append("file1", vm.dataLoker.file1);
      axios
        .post(ipBackend + "loker/register", formData)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER LOWONGAN KERJA",
              showing: true,
            });
            vm.dataLoker = "";
            vm.dataDokumen = "";
            vm.$bvModal.hide("modal-input");
            vm.$bvModal.hide("modal-konfirmasi-input");
          } else {
            vm.busy = false;
            vm.$bvModal.hide("modal-input");
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    batal() {
      this.$bvModal.hide("modal-konfirmasi-input");
    },
  },
};
</script>

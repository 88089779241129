<template>
  <div>
    <b-form>
      <b-form-group label="Judul" label-cols-md="3">
        <b-form-input
          type="text"
          :state="checkIfValid('namaLoker')"
          v-model="$v.data.namaLoker.$model"
          @change="itikiwir2()"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Posisi" label-cols-md="3">
        <multiselect
          :state="checkIfValid('masterPosisiIdEdit')"
          v-model="$v.data.masterPosisiIdEdit.$model"
          :options="posisi"
          :multiple="false"
          :searchable="true"
          :close-on-select="true"
          :show-labels="false"
          label="namaPosisi"
          track-by="masterPosisiId"
          @input="itikiwir(data.masterPosisiIdEdit)"
          placeholder="-- Pilih Posisi --"
        ></multiselect>
      </b-form-group>
      <b-form-group label="Keterangan" label-cols-md="3">
        <quill-editor
          v-model="data.keteranganLoker"
          @change="itikiwir2()"
        ></quill-editor>
      </b-form-group>
      <b-form-group label="Tanggal Akhir Lowongan" label-cols-md="3">
        <date-picker
          style="width: 100%"
          :state="checkIfValid('tanggalAkhirLoker')"
          v-model="$v.data.tanggalAkhirLoker.$model"
          :placeholder="data.tglAkhir"
          @change="itikiwir2()"
        ></date-picker>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "modalInput",
  props: ["posisi", "data", "edit"],
  components: {
    DatePicker,
    Multiselect,
    quillEditor,
  },
  data() {
    return {
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      namaLoker: {
        required,
      },
      masterPosisiIdEdit: {
        required,
      },
      tanggalAkhirLoker: {
        required,
      },
    },
  },
  watch: {
    isValid: function (newVal, oldVal) {
      if (newVal != oldVal) {
        // this.data.masterPosisiId = this.data.masterPosisiId.masterPosisiId;
        this.$emit("dataFromChild", this.data);
      }
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      this.data.masterPosisiId = x;
      this.$emit("dataFromChild", this.data);
    },
    itikiwir2() {
      this.$emit("dataFromChild", this.data);
    },
  },
};
</script>

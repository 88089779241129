<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ $store.state.table.lowongan }}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" />
                  {{ $store.state.table.tambahModal }}</b-button
                >
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>
            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <b-button
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit'"
                      v-b-modal.modal-edit
                      @click="(data = item.item), (edit = !edit)"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >
                    <b-button
                      variant="info"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Detail'"
                      v-b-modal.modal-detail
                      @click="data = item.item"
                      ><CIcon name="cil-info" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete
                      @click="data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input
      @alertFromChild="triggerAlert($event), getDatas()"
      :divisi="divisi"
      :dokumen="dokumen"
    />
    <modal-edit
      :data="data"
      :dokumen="dokumen"
      :edit="edit"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-delete
      :data="data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modalDetail :data="data" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import modalInput from "../../../component/hcms/lowonganKerja/modalInput.vue";
import modalEdit from "../../../component/hcms/lowonganKerja/modalEdit.vue";
import modalDelete from "../../../component/hcms/lowonganKerja/modalDelete.vue";
import modalDetail from "../../../component/hcms/lowonganKerja/modalDetail.vue";
import moment from "moment";
moment.locale("id");
export default {
  components: {
    modalInput,
    modalEdit,
    modalDelete,
    modalDetail,
  },
  name: "divisi",
  data() {
    return {
      showing: false,
      edit: false,
      variant: "success",
      msg: "",
      data: "",
      divisi: [],
      dokumen: [],
      items: [
        {
          no: "-",
          judulLoker: "-",
          posisi: "-",
          tglAkhir: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaLoker",
          label: "Judul Loker",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaPosisi",
          label: "Posisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tglAkhir",
          label: "Tanggal Akhir Loker",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-4 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
    this.getPosisi();
    this.getKebutuhan();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getPosisi() {
      let vm = this;
      axios
        .get(ipBackend + "masterDivisi/list")
        .then((res) => {
          vm.divisi = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getKebutuhan() {
      let vm = this;
      let listKebutuhan = await axios.get(ipBackend + "masterKebutuhan/list");
      vm.dokumen = listKebutuhan.data.data;
    },
    async getDatas() {
      this.tableBusy = true;
      let listLoker = await axios.get(ipBackend + "loker/list");
      this.items = listLoker.data.data;
      // FOREACH diganti FOR
      // this.items.forEach((element, index) => {
      //   let x = this.items[index];
      //   x.no = index + 1;
      //   x.tglAkhir = moment(x.tanggalAkhirLoker).format("LL");
      // });
      for (let i = 0; i < this.items.length; i++) {
        let x = this.items[i];
        x.no = i + 1;
        x.tglAkhir = moment(x.tanggalAkhirLoker).format("LL");
      }
      console.log(this.items, "ini itemsnya");
      this.totalRows = this.items.length;
      this.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      // vm.showing = event.showing;
      // vm.variant = event.variant;
      // vm.msg = event.msg;
      vm.$store.commit("set_alert", event);
      vm.getDatas();
      // setTimeout(() => {
      //   vm.showing = false;
      // }, 4000);
    },
  },
};
</script>
